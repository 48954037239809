import { useRouter } from 'next/router'

import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import { useTranslation } from 'next-i18next'

import Container from 'components/Container'
import InfoBox, { InfoBoxProps } from 'components/InfoBox'
import Modal from 'components/Modal'
import LanguageMenu from 'components/LanguageDropdown'
import { LogoImage } from 'components/SectionHeader'
import Link from 'next/link'
import { useState } from 'react'
import ReservationHasAccount from '../../components/ReservationHasAccount'
import posthog from 'posthog-js'

const LoginPage = ({ message, type }: InfoBoxProps) => {
  const router = useRouter()
  const { t } = useTranslation(['login', 'common'])
  const query = router.query.reserva ? String(router.query.reserva) : ''
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [reservation, setReservation] = useState<string>('')

  return (
    <div className="bg-[#1D1C1B] h-full min-h-screen">
      <main>
        <section className="py-6 bg-black">
          <Container className="flex justify-between">
            <LogoImage />
            <LanguageMenu />
          </Container>
        </section>

        <section className="w-full mt-20">
          <Modal open={isOpenModal} setIsOpen={setIsOpenModal}>
            <ReservationHasAccount reservation={reservation} />
          </Modal>

          <Container className="bg-white p-6 rounded-lg">
            <h1 className="font-serif text-[2rem] text-center mb-6 leading-10 font-semibold">
              {t('login-title')}
            </h1>

            <div>
              {message && (
                <div className="py-6">
                  <InfoBox message={t(message)} type={type} />
                </div>
              )}

              <Formik
                initialValues={{
                  reserva: query
                }}
                validationSchema={Yup.object({
                  reserva: Yup.string().required(t('errors.login-required'))
                })}
                onSubmit={async (values) => {
                  posthog.capture('click-in-without-account')
                  const isCheckinAccountLinked = await fetch(
                    `/api/cerberus/verifyCheckinAccountLinked?reservation=${values.reserva}`,
                    {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json'
                      },
                      body: JSON.stringify({
                        reserva: values.reserva
                      })
                    }
                  ).then((response) => response.json())

                  if (isCheckinAccountLinked) {
                    setReservation(values.reserva)
                    return setIsOpenModal(true)
                  }

                  router.push({
                    pathname: '/',
                    query: {
                      reserva: values.reserva.replace(/\s/g, ''),
                      check: true
                    }
                  })
                }}
              >
                {({ errors, values }) => (
                  <Form>
                    <p
                      className="text-neutral-900 pb-2 login-label"
                      dangerouslySetInnerHTML={{
                        __html: t('login-description')
                      }}
                    />
                    <Field
                      id="reserva"
                      name="reserva"
                      placeholder={t('login-placeholder')}
                      className={`rounded form-input ${
                        !!errors.reserva && 'form-input--error'
                      }`}
                    />
                    <ErrorMessage
                      name="reserva"
                      component="span"
                      className="error-message"
                    />

                    <p
                      className="mt-4 text-sm"
                      dangerouslySetInnerHTML={{
                        __html: t('login-info')
                      }}
                    />

                    <div className="flex gap-6 flex-col">
                      <Link
                        passHref
                        href={
                          `${process.env.NEXT_PUBLIC_SITE_URL}?openLogin=true` +
                          `${values.reserva && `&reserva=${values.reserva}`}`
                        }
                      >
                        <button
                          className="mt-4 button--dark w-full"
                          onClick={() =>
                            posthog.capture('click-in-with-account')
                          }
                        >
                          {t('button.with-account')}
                        </button>
                      </Link>

                      <button
                        type="submit"
                        className="underline w-full text-black mb-2"
                      >
                        {t('button.no-account')}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Container>
        </section>
      </main>
    </div>
  )
}

export default LoginPage
