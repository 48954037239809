import { NextPageContext } from 'next'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { captureException, setContext } from '@sentry/nextjs'

import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import { InfoBoxProps } from 'components/InfoBox'
import type { BookingPageProps } from 'templates/Booking'
import LoginPage from 'templates/Login'

import { CheckinStatusConstants } from 'utils/constants/CheckinStatusConstants'

import { getCheckin } from 'libs/checkin-server'

import { useBooking } from 'hooks/use-booking'
import { useBuilding } from 'hooks/use-building'
import { useGuest } from 'hooks/use-guest'
import posthog from 'posthog-js'

type HomeProps = {
  info: InfoBoxProps
  data: BookingPageProps
}

export default function Home({ info, data }: HomeProps) {
  const router = useRouter()

  const { saveBooking } = useBooking()
  const { saveBuilding } = useBuilding()
  const { saveGuest } = useGuest()

  useEffect(() => {
    if (!!data && !info) {
      saveGuest(data.guest)
      saveBooking(data.booking)
      saveBuilding(data.building)

      router.push(`/detalhes/?reserva=${data.booking.code}&check=true`)
    }
  }, [data, saveGuest, saveBooking, saveBuilding, router, info])

  useEffect(() => {
    setTimeout(() => {
      if (router.query.reserva) {
        posthog.capture('entered-check-in-with-reservation-number', {
          reserva: String(router.query.reserva)
        })
      }
    }, 0)
  }, [router.query.reserva])

  return <LoginPage {...info} />
}

export async function getServerSideProps({ query, locale }: NextPageContext) {
  try {
    if (
      query &&
      Object.keys(query).length === 0 &&
      query.constructor === Object
    ) {
      return {
        props: {
          ...(locale && (await serverSideTranslations(locale, ['login'])))
        }
      }
    }

    // Consulta reserva no backend
    const reserva = await getCheckin(String(query.reserva))

    const check = Boolean(query.check)

    if (check) {
      //*********************************************************
      // MOSTRA MSG QUE NÃO ACHOU A RESERVA ==================>
      if (!reserva) {
        return {
          props: {
            info: {
              message: 'status.booking-not-found'
            },
            ...(locale && (await serverSideTranslations(locale, ['login'])))
          }
        }
      }

      // *********************************************************************
      // AQUI ELE VERIFICA SE A RESERVA ESTÁ CANCELADA ======================>
      if (reserva.status === CheckinStatusConstants.STATUS_CANCELED) {
        return {
          props: {
            info: {
              message: 'status.booking-canceled'
            },
            ...(locale && (await serverSideTranslations(locale, ['login'])))
          }
        }
      }

      // *********************************************************************
      // AQUI ELE VERIFICA SE A RESERVA NÃO ESTÁ AGUARDANDO CONFIRMAÇÃO ======================>
      if (reserva.status === CheckinStatusConstants.STATUS_NOT_CONFIRMED) {
        return {
          props: {
            info: {
              message: 'status.booking-not-confirmed'
            },
            ...(locale && (await serverSideTranslations(locale, ['login'])))
          }
        }
      }

      // *********************************************************************
      // AQUI ELE VERIFICA SE A RESERVA NÃO ESTÁ FEZ CHECK-OUT OU É NO-SHOW ======================>
      if (
        reserva.status === CheckinStatusConstants.STATUS_CHECKED_OUT ||
        reserva.status === CheckinStatusConstants.STATUS_NO_SHOW
      ) {
        return {
          props: {
            info: {
              message: 'status.booking-ended'
            },
            ...(locale && (await serverSideTranslations(locale, ['login'])))
          }
        }
      }

      const mapsLink = ''
      const uberLink = ''

      const webCheckinCode = ''
      const checkinTime = '15:00'
      const checkoutTime = '11:00'

      return {
        props: {
          data: {
            guest: {
              name: reserva.guests[0]?.name ?? 'Hóspede',
              bookingGuests: reserva.guests
            },
            booking: {
              code: reserva.reservation,
              status: reserva.status,
              checkinDate: reserva.checkin,
              checkoutDate: reserva.checkout,
              webCheckinCode,
              checkinTime,
              checkoutTime,
              bookingId: reserva.reservation,
              adults: reserva.adults,
              children: reserva.children,
              guestsTotal: reserva.guests_total,
              hasSecondGuest: reserva.hasSecondGuest
            },
            building: {
              name: reserva.building.name,
              id: reserva.building.code,
              address: reserva.building.address.address_line_1,
              city: reserva.building.address.address_city,
              roomId: reserva.apartments[0]
                ? reserva.apartments[0].room_id
                : null,
              image: '/img/cover.png',
              imageDescription: 'image cover',
              mapsLink,
              uberLink
            },
            hasSecondGuest: reserva.hasSecondGuest
          },
          ...(locale &&
            (await serverSideTranslations(locale, ['login', 'details'])))
        }
      }
    }

    return {
      props: {
        ...(locale && (await serverSideTranslations(locale, ['login'])))
      }
    }
  } catch (err) {
    setContext('query', { query })
    captureException(err)

    return {
      props: {
        info: {
          message: 'status.booking-error'
        },
        ...(locale && (await serverSideTranslations(locale, ['login'])))
      }
    }
  }
}
